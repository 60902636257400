<template>
  <div class="thirdpartyform">

      <!-- Place this div where you want the form to appear: -->
      <div id="embeddable-form-container"></div>
  </div>
</template>

<script>
export default {};
</script>
